import React, { useEffect, useState } from "react";
import { Pagination, Table } from "antd";
import Column from "antd/lib/table/Column";
import { useDispatch, useSelector } from "react-redux";
import insuransersActions from "services/insuransers/actions";

const ListAgentsMoreC = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { budgetAll } = useSelector((state) => state.insuransers.success);

  useEffect(() => {
    dispatch(insuransersActions.getBudgetAll(page));
  }, [dispatch, page]);

  const onChange = (pageNumber) => {
    setPage(pageNumber);
    dispatch(insuransersActions.getBudgetAll({ page: pageNumber }));
  };

  return (
    <div className="insuransers_table">
      <Table sticky dataSource={budgetAll?.items}>
        <Column
          title="ID"
          key="id"
          dataIndex="id"
          className="item"
          width={70}
        />
        <Column
          title="Nombre del agente"
          key="nameAgent"
          className="item-name"
          render={(_, record) => {
            return <span>{record.agent?.user.person.name}</span>;
          }}
        />
        <Column
          title="Saldo actual"
          key="amount"
          className="item"
          render={(_, record) => {
            const USDollar = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            });
            return <span>{USDollar.format(record.total)} USD</span>;
          }}
        />
      </Table>
      <Pagination
        defaultCurrent={page}
        total={budgetAll?.meta?.totalItems}
        pageSize={10}
        onChange={onChange}
      />
    </div>
  );
};

export default ListAgentsMoreC;
